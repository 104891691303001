import RNLottieView, { AnimationObject } from 'lottie-react-native';
import { ComponentProps } from 'react';
import { View } from 'react-native';

// https://github.com/chromaui/chromatic-cli/blob/c971fd0c7746bf396e2571f9ec0ad4d81e145499/isChromatic.js#L1
function isChromatic() {
  const windowToCheck = typeof window !== 'undefined' && window;
  return !!(
    windowToCheck &&
    (windowToCheck.navigator?.userAgent?.match(/Chromatic/) ||
      windowToCheck.location?.href?.match(/chromatic=true/))
  );
}

/**
 * Renders a lottie view that takes up full width by default while maintaining
 * aspect ratio of the source asset
 */
export function LottieView(props: Omit<ComponentProps<typeof RNLottieView>, 'style'>) {
  let ratio;
  const source = props.source as AnimationObject;
  if ('w' in source && 'h' in source) {
    ratio = source.w / source.h;
  }

  return (
    <View
      // needed for web layout
      style={[{ width: '100%', aspectRatio: ratio }]}
    >
      <RNLottieView
        {...props}
        loop={isChromatic() ? false : props.loop}
        // needed for native layout
        style={[{ width: '100%', aspectRatio: ratio }]}
      />
    </View>
  );
}
