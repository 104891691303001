import '@expo/metro-runtime';

import { Platform } from 'react-native';

// https://moti.fyi/installation#using-inline-requires
import 'react-native-reanimated';

import { registerRootComponent } from 'expo';
import { activateKeepAwakeAsync } from 'expo-keep-awake';

import 'expo-asset';

import * as SplashScreen from 'expo-splash-screen';

// init amplitude
import '../lib/log';
import '../sentry';

import * as Sentry from '@sentry/react-native';

import loadingPromise from '../lib/mountApp.platform';

export * from '../types/global';

if (__DEV__ && Platform.OS !== 'web') {
  void activateKeepAwakeAsync();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mountApp(app: React.ComponentType<any>) {
  if (Platform.OS !== 'web') {
    SplashScreen.setOptions({ fade: true });
    SplashScreen.preventAutoHideAsync().catch(() => {
      // noop, prevent unused warning about splash already hidden
    });
  }
  loadingPromise
    .then(() => {
      const App = Sentry.wrap(app);
      registerRootComponent(App);
    })
    .catch((e) => {
      Sentry.captureException(e);
      throw e;
    });
}
